import { useEffect, useState } from "react";
import { useAuthProvider } from "../../core/authContext";
import { useGetPropertyUsersQuery} from "../../generated/graphql";

const useUsers = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();
  const [users, setUsers]=useState<any>([])

  const { data:userList, loading: loadingUserList } = useGetPropertyUsersQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
    },
  });

  useEffect(() => {
    if (userList?.getPropertyUsers) {
      const sortedUsers = userList?.getPropertyUsers
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
        setUsers(sortedUsers);
    } else {
      setUsers([]);
    }
  }, [userList]);

  return {
    users,
    loading:loadingUserList,
  };
};

export default useUsers;
