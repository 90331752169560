import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  //   Button,
  //   CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  //   Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { EMenu, useAddEMenuMutation } from "../../generated/graphql";

interface prop {
  editData: EMenu | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}

const EMenuForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  //   const [image, setImage] = React.useState<any>("");
  //   const [uploadIcon, setUploadIcon] = React.useState<any>("");
  //   const [isDisable, setIsDisable] = React.useState(true);
  //   React.useEffect(() => {
  //     if (!open) {
  //       setImage("");
  //       setUploadIcon("");
  //     }
  //   }, [open]);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: EMenuReset,
  } = useForm<EMenu>({
    defaultValues: {
      name: editData?.name || "",
    },
  });

  //   const [getUploadSignedURL, { loading: loadingImage }] =
  //     useGetUploadSignedUrlLazyQuery({
  //       fetchPolicy: "network-only",
  //     });

  React.useEffect(() => {
    EMenuReset(
      editData || {
        name: "",
      }
    );
  }, [editData, EMenuReset]);

  const [upsertEMenu] = useAddEMenuMutation();

  const onSubmit = async (data: EMenu) => {
    try {
      const res = await upsertEMenu({
        variables: {
          eMenu: {
            id: editData?.id || null,
            name: data.name,
            property_id: propertyId,
          },
        },
      });
      if (res.data?.upsertEMenu) {
        setOpen(false);
        // setImage("");
        swal({
          text: "E-Menu Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  //   const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.files != null) {
  //       setImage(URL.createObjectURL(e.target.files[0]));

  //       const localFile = e.target.files[0];
  //       const filename = localFile.name;
  //       const fileType = localFile.type;
  //       const extension = filename.split(".")[1];
  //       const propertyID = propertyId;
  //       try {
  //         const { data: awsData } = await getUploadSignedURL({
  //           variables: {
  //             fileType,
  //             extension,
  //             propertyID,
  //           },
  //         });

  //         const {
  //           getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
  //         } = awsData as any;

  //         const picture = await fetch(URL.createObjectURL(e.target.files[0]));
  //         const pictureBlob = await picture.blob();
  //         const file = new File([pictureBlob], filename);

  //         await fetch(presigned_upload_url, {
  //           method: "PUT",
  //           body: file,
  //           headers: {
  //             "Content-Type": fileType,
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         });
  //         setUploadIcon(uploadedImageURL);
  //         setIsDisable(false);
  //       } catch (error) {
  //         swal({
  //           text: `${error}`,
  //           icon: "error",
  //         });
  //       }
  //     }
  //   };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update E-Menu" : "Add New E-Menu"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Sub Property form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter E-Menu Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            {/* <Grid item md={12}>
            <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || image) && (
                  <Box>
                    <img
                      alt="Room"
                      width={"250px"}
                      src={image ? image : editData?.img}
                    />

                    {image && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid> */}
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EMenuForm;
