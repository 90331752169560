import { useEffect, useState } from "react";
import { useAuthProvider } from "../../core/authContext";
import { useGetOrdersQuery } from "../../generated/graphql";
import { endOfDay, startOfDay, sub } from "date-fns";

export enum TypesOfTimePeriod {
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last 7 Days",
  Last30Days = "Last 30 Days",
  Custom = "Custom",
}

const useReports = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();
  const [timePeriod, setTimePeriod] = useState<string>(TypesOfTimePeriod.Today);
  const [startDate, setStartDate] = useState<string>(startOfDay( new Date()).toISOString());
  const [endDate, setEndDate] = useState<string>( new Date().toISOString());
  const [row, setRow] = useState<any>([]);

  const { data:orderResponse, loading: loadingOrders } = useGetOrdersQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
      startDate: startDate,
      endDate: endDate,
    },
    skip:!property?.id || !startDate || !endDate || !timePeriod
  });


  useEffect(() => {

    if (orderResponse?.getOrders) {
      const sortedRow = orderResponse?.getOrders
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
      setRow(sortedRow);
    } else {
      setRow([]);
    }
  }, [orderResponse]);

  useEffect(() => {
    const now = new Date();
    switch (timePeriod) {
      case TypesOfTimePeriod.Today:
        setStartDate(startOfDay(now).toISOString());
        setEndDate(now.toISOString());
        break;

      case TypesOfTimePeriod.Yesterday:
        setStartDate(startOfDay(sub(now, { days: 1 })).toISOString());
        setEndDate(endOfDay(sub(now, { days: 1 })).toISOString());

        break;

      case TypesOfTimePeriod.Last7Days:
        setStartDate(startOfDay(sub(now, { days: 7 })).toISOString());
        setEndDate(endOfDay(sub(now, { days: 1 })).toISOString());

        break;

      case TypesOfTimePeriod.Last30Days:
        setStartDate(startOfDay(sub(now, { days: 30 })).toISOString());
        setEndDate(endOfDay(sub(now, { days: 1 })).toISOString());
        break;
    }
  }, [timePeriod]);

  return {
    row,
    loadingOrders,
    timePeriod,
    setTimePeriod,
    setStartDate,
    setEndDate,
  };
};

export default useReports;
