import { Add } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import useEdible from "./hotel-edible-hook";
import Loader from "../../components/Loader";
import DineInMenuForm from "./Dine-In-Menu-form";
import useCategory from "../Dine-In-Categories/dine-in-category-hooks";
import useSection from "../Sections/dine-in-section-hook";
import useAllergen from "../Allregens/allergen-hooks";
import { groupBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DraggableItem from "../../components/DraggableItem";

const HotelEdible = () => {
  const {
    edibleItems,
    loadingEdibleItems,
    handleClickEditOpen,
    handleEditClose,
    handleDelete,
    editData,
    open,
    property,
    setOpen,
    refetchItems,
    onSave,
    onToggle,
    handleSyncPOSMenu,
  } = useEdible();
  const { Categories } = useCategory();
  const { Sections } = useSection();
  const { Allergens } = useAllergen();

  const groupedMenu = groupBy(edibleItems, "type");

  if (loadingEdibleItems) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Edibles Item
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleSyncPOSMenu()}
            sx={{ mr: 1 }}
          >
            Sync POS Menu
          </Button>

          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {Object.keys(groupedMenu)
          .sort()
          .map((key) => {
            return (
              <React.Fragment key={key}>
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  sx={{ width: "100%", paddingLeft: "15px" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          fontSize: "25px",
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginLeft: "20px",
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    >
                      {key}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                      <DraggableItem
                        itemArray={groupedMenu[key]}
                        handleDelete={handleDelete}
                        handleClickEditOpen={handleClickEditOpen}
                        onToggle={onToggle}
                        onSave={onSave}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            );
          })}
        {/* Edit Dialog */}
        <DineInMenuForm
          allergens={Allergens}
          categories={Categories}
          sections={Sections}
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={property.id}
          refetchItems={refetchItems}
          setOpen={setOpen}
        />
      </Grid>
    </>
  );
};

export default HotelEdible;
