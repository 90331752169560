import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetEMenuQuery,
  Types,
  DineInItem,
  useAddEMenuMutation,
  Diet_Type,
} from "../../generated/graphql";

import swal from "sweetalert";
import { useParams } from "react-router-dom";

const useItemList = () => {
  const { EMenuId } = useParams();
  const [editData, setEditData] = React.useState<DineInItem | null>(null);

  const [open, setOpen] = React.useState(false);

  const handleClickEditOpen = (data: DineInItem | null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditClose = (reason: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    setOpen(false);
    setEditData(null);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  const {
    data: eMenu,
    loading: loadingEMenu,
    refetch: refetchEMenu,
  } = useGetEMenuQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
      id: EMenuId || "",
    },
    skip: !EMenuId,
  });

  // change the status
  const [upsertEMenu] = useAddEMenuMutation();

  const handleDelete = async (id: string) => {
    const eMenuItems = JSON.parse(
      JSON.stringify(eMenu?.getEMenu?.items, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );
    const newItemList = eMenuItems
      ?.filter((item: DineInItem) => item?.id !== id)
      .map((item: DineInItem) => ({
        ...item,
        property_id: property.id,
        desc: item?.desc || "",
        img: item?.img || "",
        name: item?.name || "",
        price: item?.price || 0,
        type: item?.type || "",
      }));

    try {
      await upsertEMenu({
        variables: {
          eMenu: {
            id: eMenu?.getEMenu?.id,
            name: eMenu?.getEMenu?.name || "",
            items: newItemList || [],
            property_id: property?.id,
          },
        },
      });

      swal({
        text: "Item Deleted Successfully",
        icon: "success",
      });
      refetchEMenu();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onSave = async (data: DineInItem) => {
    const cleanData = JSON.parse(
      JSON.stringify(data, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );

    const eMenuItems = JSON.parse(
      JSON.stringify(eMenu?.getEMenu?.items, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );
    const newItemList = eMenuItems
      ?.filter((item: DineInItem) => item?.id !== data.id)
      .map((item: DineInItem) => ({
        ...item,
        property_id: property.id,
        desc: item?.desc || "",
        img: item?.img || "",
        name: item?.name || "",
        price: item?.price || 0,
        type: item?.type || "",
      }));

    newItemList.push({
      ...cleanData,
      property_id: property.id,
    });

    try {
      await upsertEMenu({
        variables: {
          eMenu: {
            id: eMenu?.getEMenu?.id,
            name: eMenu?.getEMenu?.name || "",
            items: newItemList || [],
            property_id: property?.id,
          },
        },
      });

      swal({
        text: "Item updated Successfully",
        icon: "success",
      });
      refetchEMenu();
      setOpen(false);
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onDrag = async (data: DineInItem[]) => {
    const cleanData = JSON.parse(
      JSON.stringify(data, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );

    const newData = cleanData.map((i: DineInItem) => {
      return { ...i, property_id: property?.id };
    });

    try {
      await upsertEMenu({
        variables: {
          eMenu: {
            id: eMenu?.getEMenu?.id,
            name: eMenu?.getEMenu?.name || "",
            items: newData || [],
            property_id: property?.id,
          },
        },
      });

      swal({
        text: "Sequence updated Successfully",
        icon: "success",
      });
      refetchEMenu();
      setOpen(false);
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onToggle = async (checked: boolean, id: string) => {
    console.log(id);

    // try {
    //   await updateStatus({
    //     variables: {
    //       id: id,
    //       propertyID: property?.id,
    //       status: checked,
    //     },
    //   });
    //   swal({
    //     text: "status change",
    //     icon: "success",
    //   });
    //   refetchItems();
    // } catch (err) {
    //   swal({
    //     text: `${err}`,
    //     icon: "error",
    //   });
    // }
  };

  return {
    edibleItems: eMenu?.getEMenu?.items || [],
    categories: [
      Types.Alcohol,
      Types.Breakfast,
      Types.Desserts,
      Types.Drinks,
      Types.HotDrinks,
      Types.Mains,
      Types.Salads,
      Types.Soups,
      Types.Starters,
    ],
    Diet: [Diet_Type.Vegan, Diet_Type.NonVegan, Diet_Type.None],
    loadingEMenu,
    handleClickEditOpen,
    handleEditClose,
    handleDelete,
    open,
    editData,
    setOpen,
    refetchEMenu,
    property,
    eMenu: eMenu?.getEMenu,
    onToggle,
    onSave,
    onDrag,
  };
};

export default useItemList;
