import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { Add, EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import useSubProperty from "./sp-hook";
import Loader from "../../components/Loader";
import SPForm from "./sp-form";


const Item = ({
    id,
    name,
    img,
    handleDelete,
    handleClickEditOpen,
    subService_names
  }: {
    id: any;
    name: any;
    img:any;
    handleDelete: any;
    handleClickEditOpen: any;
    subService_names: string[] | null;
  }) => {
    const index = Number(id);
  
    let height: string | number = "initial";
    if (index % 3 === 0) {
      height = 110;
    } else if (index % 4 === 0) {
      height = 70;
    }
  
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          borderBottom: "1px solid #eee",
        }}
        style={{ height, backgroundColor: "white" }}
      >
        <div
          style={{
            display: "flex",
            flex: 2,
            alignItems: "center",
          }}
        >
          {name}
        </div>
        <div
          style={{
            display: "flex",
            flex: 2,
            alignItems: "center",
          }}
        >
          {<img src={img} width={70} height={70} alt={name}></img>}
        </div>
        <div
          style={{
            display: "flex",
            flex: 2,
            alignItems: "center",
          }}
        >
          {subService_names?.join(", ")}
        </div>
        <IconButton
          onClick={() => handleClickEditOpen()}
          color="info"
          aria-label="edit"
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          onClick={() => handleDelete()}
          color="error"
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };
const SubProperty = () => {
    const {SubProperties,
        handleClickEditOpen,
        handleDelete,
        editData,
        handleEditClose,
        open,
        setOpen,
        propertyId,
        loadingSubProperty,
        refetchSubProperty
    } = useSubProperty();
    return (
        <>
        <Grid container alignItems={"center"} spacing={2} mb={2}>
            <Grid item>
            <Typography variant="h4" fontWeight={600}>
                SubProperty Management
            </Typography>
            </Grid>
            <Grid item display={"flex"} alignItems={"stretch"}>
            <Button
                size="large"
                disableElevation={false}
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleClickEditOpen(null)}
                sx={{ mr: 1 }}
            >
                Add SubProperty
            </Button>
            </Grid>
        </Grid>
        {loadingSubProperty ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Box
          sx={{
            border: "1px solid #ddd",
            overflowY: "auto",
            borderRadius: "4px",
            m: 2,
            width: "100%",
          }}
        >
          <Box
           sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            borderBottom: "1px solid #eee",
          }}
          style={{ height:'initial', opacity: 1, backgroundColor: "white" }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Name"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "2 0 7%",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Image"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "2 0 7%",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Subservices"}
            </div>
            <div     
                style={{
                display: "flex",
                flex: '0.5',
                alignItems: "center",
                fontWeight: "bold",
              }}>
                
            </div>
          </Box>
          {SubProperties?.map((item: any, i: any) => (
            <Item
              key={item?.id}
              id={item?.id}
              name={item?.name}
              img={item?.img}
              subService_names={item?.sub_services?.map((subService: any) => subService?.name)}
              handleDelete={() => handleDelete(item?.id)}
              handleClickEditOpen={() => handleClickEditOpen(item)}
            />
          ))}
        </Box>
          <SPForm
            editData={editData}
            open={open}
            handleEditClose={handleEditClose}
            propertyId={propertyId}
            refetch={refetchSubProperty}
            setOpen={setOpen}
          />
        </Grid>
      )}
        </>
        
    )
}

export default SubProperty