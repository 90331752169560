import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { Add, EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import useEMenu from "./eMenu-hook";
import Loader from "../../components/Loader";
import EMenuForm from "./eMenu-form";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

const Item = ({
  id,
  name,
  img,
  handleDelete,
  handleClickEditOpen,
  QR_BASE_URL,
}: {
  id: any;
  name: any;
  img: any;
  handleDelete: any;
  handleClickEditOpen: any;
  QR_BASE_URL: string;
}) => {
  const index = Number(id);
  const navigate = useNavigate();

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/e-menu/${id}`)}
      >
        {name}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        <QRCode value={`${QR_BASE_URL}/${id}`} size={100} />
      </div>
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};
const EMenu = () => {
  const {
    EMenus,
    handleClickEditOpen,
    handleDelete,
    editData,
    handleEditClose,
    open,
    setOpen,
    propertyId,
    loadingEMenu,
    refetchEMenu,
    QR_BASE_URL,
  } = useEMenu();

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            E-Menu Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add E-Menu
          </Button>
        </Grid>
      </Grid>
      {loadingEMenu ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Box
            sx={{
              border: "1px solid #ddd",
              overflowY: "auto",
              borderRadius: "4px",
              m: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "15px",
                borderBottom: "1px solid #eee",
              }}
              style={{
                height: "initial",
                opacity: 1,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {"Name"}
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flex: "2 0 7%",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {"QR"}
              </div> */}
              <div
                style={{
                  display: "flex",
                  flex: "0.5",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              ></div>
            </Box>
            {EMenus?.map((item: any, i: any) => (
              <Item
                key={item?.id}
                id={item?.id}
                name={item?.name}
                img={item?.img}
                handleDelete={() => handleDelete(item?.id)}
                handleClickEditOpen={() => handleClickEditOpen(item)}
                QR_BASE_URL={QR_BASE_URL}
              />
            ))}
          </Box>
          <EMenuForm
            editData={editData}
            open={open}
            handleEditClose={handleEditClose}
            propertyId={propertyId}
            refetch={refetchEMenu}
            setOpen={setOpen}
          />
        </Grid>
      )}
    </>
  );
};

export default EMenu;
