import { Box } from "@mui/material";
import React from "react";

export default function MenuIcon({ source }: { source: string }) {
  return (
    <Box
      component={"img"}
      src={source}
      style={{
        width: "24px",
        height: "24px",
        marginRight: "6px",
        marginBottom: "5px",
      }}
    ></Box>
  );
}
