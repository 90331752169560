import { EditOutlined } from "@mui/icons-material";
import { Grid, Typography, IconButton, Button, Box } from "@mui/material";
import * as React from "react";
import Loader from "../../components/Loader";
import useHotlyncServices from "./services-hooks";
import HotlyncServicesForm from "./service-form";
import SyncIcon from "@mui/icons-material/Sync";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Draggable,
  moveItems,
  useDraggable,
  useDraggableContext,
} from "react-tiny-dnd";
import { useAutoScroll } from "react-tiny-autoscroll";

const Item = ({
  id,
  sequence,
  listeners,
  isDragging,
  handleClickEditOpen,
  name,
}: {
  id: any;
  name: any;
  sequence: any;
  listeners: any;
  isDragging: any;
  handleClickEditOpen: any;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {name}
      </div>

      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  handleClickEditOpen,
}: {
  index: any;
  context: any;
  item: any;
  handleClickEditOpen: any;
}) => {
  const { listeners, isDragging } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.name}
        listeners={listeners}
        isDragging={isDragging}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
      />
    </Draggable>
  );
};

const Services = () => {
  const {
    Services,
    handleClickEditOpen,
    editData,
    handleEditClose,
    open,
    propertyId,
    refetchHotlyncService,
    setOpen,
    loadingServices,
    syncHotlyncServices,
    onSave,
    isCustomized,
    setIsCustomized
  } = useHotlyncServices();

  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (Services) {
      setItems(
        Services
      );
    }
  }, [Services]);
  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = React.useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });

  if (loadingServices) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Service Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<SyncIcon />}
            onClick={() => syncHotlyncServices()}
            sx={{ mr: 1 }}
          >
            Sync Hotlync Services
          </Button>
        </Grid>
      </Grid>
      {loadingServices ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Box
            ref={containerRef}
            sx={{
              border: "1px solid #ddd",
              overflowY: "auto",
              borderRadius: "4px",
              m: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "15px",
                borderBottom: "1px solid #eee",
              }}
              style={{
                height: "initial",
                opacity: 1,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {"Sequence"}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1.8,
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {"Name"}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "0.2",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              ></div>
            </Box>
            {items?.map((item: any, i: any) => (
              <DraggableItem
                context={context}
                key={item.id}
                index={i}
                item={item}
                handleClickEditOpen={handleClickEditOpen}
              />
            ))}
          </Box>
          <HotlyncServicesForm
            editData={editData}
            open={open}
            handleEditClose={handleEditClose}
            propertyId={propertyId}
            refetch={refetchHotlyncService}
            setOpen={setOpen}
            isCustomized={isCustomized}
            setIsCustomized={setIsCustomized}
          />
        </Grid>
      )}
    </>
  );
};

export default Services;