import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import Loader from "../../components/Loader";
import NoteViewer from "../../components/Editor";
import { LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";

import useHotelInformation from "./hotel-info-hook";
import { Controller } from "react-hook-form";

const HotelInformation = () => {
  const {
    register,
    handleSubmit,
    onSubmitMetaData,
    handleImage,

    setMetaValue,

    loading,
    metaWatch,

    hotelDetails,

    control,
    signInToken,
  } = useHotelInformation();

  const onInitialEditorState = useCallback(
    (editor: LexicalEditor, fieldKey: string) => {
      let htmlString = "";
      switch (fieldKey) {
        case "address":
          htmlString = hotelDetails?.dir_con.address || "";
          break;
        case "feedback_desc":
          htmlString = hotelDetails?.feedback_desc || "";
          break;
        case "directory_description":
          htmlString = hotelDetails?.directory_description || "";
          break;
        case "promotion_desc":
          htmlString = hotelDetails?.promotion_desc || "";
          break;
        case "description":
          htmlString = hotelDetails?.about.desc || "";
          break;
        default:
          break;
      }
      if (!htmlString.startsWith("<")) {
        htmlString = `<p>${htmlString}</p>`;
      }
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      $insertNodes(nodes);
    },
    [hotelDetails]
  );

  const onFormattingChange = useCallback(
    (fieldKey: string, editorInstance?: LexicalEditor) => {
      if (editorInstance) {
        editorInstance.update(() => {
          const htmlString = $generateHtmlFromNodes(editorInstance, null);
          switch (fieldKey) {
            case "address":
              setMetaValue("address", htmlString);
              break;
            case "feedback_desc":
              setMetaValue("feedback_desc", htmlString);
              break;
            case "directory_description":
              setMetaValue("directory_description", htmlString);
              break;
            case "promotion_desc":
              setMetaValue("promotion_desc", htmlString);
              break;
            case "description":
              setMetaValue("description", htmlString);
              break;
            default:
              break;
          }
        });
      }
    },
    [setMetaValue]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box component={"form"} onSubmit={handleSubmit(onSubmitMetaData)}>
          <Typography variant="h4" align="center" fontWeight={600}>
            Hotel Information
          </Typography>
          <Grid container spacing={1} pt={2}>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Hotel Name"
                {...register("hotel_name", {
                  required: "Hotel Name is required!",
                })}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Email"
                {...register("email")}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Phone"
                {...register("phone")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      Boolean(metaWatch("has_order_service_id")) || false
                    }
                  />
                }
                {...register("has_order_service_id")}
                label="has Service ID"
              />
            </Grid>
            {metaWatch("has_order_service_id") && (
              <Grid item md={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Hotlync Order Service ID"
                  {...register("order_service_id")}
                />
              </Grid>
            )}

            <Grid item md={6}>
              <TextField
                fullWidth
                value={signInToken}
                // InputLabelProps={{ shrink: true }}
                label="Simphony Access Token"
                disabled
              />
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Address
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("address", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "address");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Directory Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("directory_description", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "directory_description");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Add Property Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("description", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "description");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Promotion Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("promotion_desc", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "promotion_desc");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Feedback Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("feedback_desc", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "feedback_desc");
              }}
            />
          </Stack>
          <Grid container spacing={1} pt={2}>
            <Grid container spacing={1} m={3} mt={1}>
              {/* About Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select About Image
                </Typography>
                <Box>
                  <img
                    alt="about img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("aboutImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update About
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "aboutImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"about"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="about"
                />
              </Grid>
              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Direction Image
                </Typography>
                <Box>
                  <img
                    alt="direction img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("directionImg") || "assets/defaultImg.jpg"}
                  />
                </Box>

                <Button variant="outlined" style={{ marginLeft: "-10px" }}>
                  <label>
                    Update Direction
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "directionImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"directory"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="directory"
                />
              </Grid>

              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Dining Image
                </Typography>
                <Box>
                  <img
                    alt="dining img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("diningImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Dining
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "diningImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"dining"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="dining"
                />
              </Grid>

              {/* Select Hotel Service Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Service Image
                </Typography>
                <Box>
                  <img
                    alt="service img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("serviceImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Service
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "serviceImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"hotel_services"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="services"
                />
              </Grid>

              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Rooms Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("roomsImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Room
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "roomsImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"hotel_rooms"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="rooms"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} m={2}>
              <Stack direction="row" spacing={2} margin="auto">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  // disabled={!isDirty && isDisable}
                  loading={false}
                >
                  Update Data
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HotelInformation;
