import { snakeCase } from "lodash";
import * as React from "react";
import { useAuthProvider } from "../../core/authContext";
import swal from "sweetalert";
import {
  HotlyncServices,
  useGetHotlyncServicesQuery,
  usePropertyServicesQuery,
  useUpdateHotlyncServiceMutation,
} from "../../generated/graphql";

export const useServiceList = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();
  const { data, loading } = usePropertyServicesQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      property_id: property?.id,
    },
  });

  const computedData = React.useMemo(() => {
    if (!data) {
      return { servicesList: [], groupedServices: [] };
    }
    const parsedTaskList = data.getServices
      ? JSON.parse((data.getServices as any)?.tasklist)
      : [];
    const servicesList = Object.keys(parsedTaskList).map(snakeCase);

    return {
      servicesList,
    };
  }, [data]);
  return { ...computedData, loading };
};

const useHotlyncServices = () => {
  const { servicesList, loading: serviceListLoading } = useServiceList();
  const [editData, setEditData] = React.useState<HotlyncServices | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isCustomized, setIsCustomized] = React.useState<boolean>(false);
  const { getUser } = useAuthProvider();
  const property = getUser();

  const handleClickEditOpen = (data: HotlyncServices | null) => {
    setEditData(data);
    setOpen(true);
    setIsCustomized(data?.uploaded_icon ? true : false)
  };
  const [upsertHotlyncServices] = useUpdateHotlyncServiceMutation();

const syncHotlyncServices = async () => {
  try {
    for (const serviceName of servicesList) {
      await upsertHotlyncServices({
        variables: {
          hotlyncServiceInput: {
            id: "",
            name: serviceName,
            property_id: property?.id,
          },
        },
      });
    }
  } catch (err) {
    swal({
      text: `${err}`,
      icon: "error",
    });
  }
};
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  // getHotlyncServices
  const {
    data: hotlyncServices,
    refetch: refetchHotlyncService,
    loading: loadingServices,
  } = useGetHotlyncServicesQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      property_id: property?.id,
    },
  });

  const sortedServices=React.useMemo(()=>{
    return [...(hotlyncServices?.getHotlyncServices || [])].sort(
       (a, b) => (a?.sequence || 0) - (b?.sequence || 0) as any
     );
   
   },[hotlyncServices?.getHotlyncServices])

  const onSave = async (data: HotlyncServices) => {
    try {
      const res = await upsertHotlyncServices({
        variables: {
          hotlyncServiceInput: {
            id: data?.id || "",
            property_id: property?.id,
            sequence:data?.sequence,
            name:data?.name
          },
        },
      });
      if (res.data?.upsertHotlyncServices?.id) {
        swal({
          text: "Service Updated Successfully",
          icon: "success",
        });
        refetchHotlyncService();
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    Services: sortedServices,
    handleClickEditOpen,
    handleEditClose,
    editData,
    refetchHotlyncService,
    propertyId: property?.id,
    open,
    setOpen,
    loadingServices,
    serviceListLoading,
    syncHotlyncServices,
    onSave,
    isCustomized,
    setIsCustomized
  };
};

export default useHotlyncServices;
