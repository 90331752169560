import * as React from "react";
import swal from "sweetalert";
import { useAuthProvider } from "../../core/authContext";
import {
  EMenu,
  useGetAllEMenuQuery,
  useDeleteEMenuMutation,
  useAddEMenuMutation,
} from "../../generated/graphql";
import { useDomain } from "../../hooks/useDomain";
const useEMenu = () => {
  const [editData, setEditData] = React.useState<EMenu | null>(null);
  const [open, setOpen] = React.useState(false);
  const domain = useDomain();
  const QR_BASE_URL = `${domain}.myhotlyncapp.com/e-menu`;
  const handleClickEditOpen = (data: EMenu | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add allergens
  const {
    data: EMenus,
    refetch: refetchEMenu,
    loading: loadingEMenu,
  } = useGetAllEMenuQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const [deleteEMenu] = useDeleteEMenuMutation();
  const [upsertEmenu] = useAddEMenuMutation();

  // Delete Sub Property
  const handleDelete = async (id: string) => {
    try {
      await deleteEMenu({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "E-Menu Deleted Successfully",
        icon: "success",
      });
      refetchEMenu();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleAttachedSS = async (data: EMenu) => {
    try {
      const res = await upsertEmenu({
        variables: {
          eMenu: {
            id: data?.id,
            name: data?.name,
            property_id: data?.property_id,
          },
        },
      });
      if (res.data?.upsertEMenu) {
        swal({
          text: "Sub Property Added Successfully",
          icon: "success",
        });
      }
      refetchEMenu();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    EMenus: EMenus?.getAllEMenu || [],
    refetchEMenu,
    handleClickEditOpen,
    handleEditClose,
    editData,
    propertyId: property?.id,
    open,
    setOpen,
    handleDelete,
    loadingEMenu,
    handleAttachedSS,
    QR_BASE_URL,
  };
};

export default useEMenu;
