import { Typography, Divider } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink } from "react-router-dom";
import * as React from "react";
import MenuIcon from "../components/Icon/MenuIcon";

const NavList = () => {
  const NavData = [
    {
      name: "Hotel Information",
      icon: <MenuIcon source={"/assets/HotelInformation.svg"} />,
      path: "/",
    },
    {
      name: "Facilities",
      icon: <MenuIcon source={"/assets/Facilities.svg"} />,
      path: "/hotel-services",
    },
    {
      name: "Services",
      icon: <MenuIcon source={"/assets/Services.svg"} />,
      path: "/services",
    },
    {
      name: "Rooms",
      icon: <MenuIcon source={"/assets/Rooms.svg"} />,
      path: "/rooms",
    },
    // {
    //   name: "Dining",
    //   icon: <LocalBarIcon/>,
    //   path: "/dining",
    // },
    {
      name: "Dine In Menu",
      icon: <MenuIcon source={"/assets/DineinMenu.svg"} />,
      path: "/hotel-edible",
    },
    {
      name: "Promotions",
      icon: <MenuIcon source={"/assets/Promotions.svg"} />,
      path: "/promotions",
    },
    {
      name: "Dine In Categories",
      icon: <MenuIcon source={"/assets/Categories.svg"} />,
      path: "/categories",
    },
    {
      name: "Dine In Sections",
      icon: <MenuIcon source={"/assets/Sections.svg"} />,
      path: "/sections",
    },
    {
      name: "Allergens",
      icon: <MenuIcon source={"/assets/allergen.svg"} />,
      path: "/allergens",
    },
    {
      name: "Reports",
      icon: <BackspaceIcon />,
      path: "/reports",
    },
    {
      name: "Itinerary",
      icon: <MenuIcon source={"/assets/Itinerary.svg"} />,
      path: "/itinerary",
    },
    {
      name: "Users",
      icon: <SettingsIcon />,
      path: "/users",
    },
    {
      name: "Settings",
      icon: <MenuIcon source={"/assets/Settings.svg"} />,
      path: "/settings",
    },
    {
      name: "Sub Property",
      icon: <MenuIcon source={"/assets/Facilities.svg"} />,
      path: "/sub-property",
    },
    {
      name: "E-Menu",
      icon: <MenuIcon source={"/assets/DineinMenu.svg"} />,
      path: "/e-menu",
    },
  ];

  return (
    <div className="px-2">
      <Typography
        sx={{
          mx: 7,
          my: 2,
          display: { xs: "none", md: "flex" },
          fontWeight: "bold",
        }}
        width={"150px"}
        src={"https://ennovatech.com/assets/images/company-logo/hotlync.svg"}
        component={"img"}
      />
      <Divider />
      <div className="px-2 mt-3">
        {NavData.map((v) => {
          return (
            <NavLink to={v.path} className="sidebar-list" key={v.name}>
              <span>{v.icon}</span>
              <h4 className="mb-0">{v.name}</h4>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default NavList;
