import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetUploadSignedUrlLazyQuery,
  useGetHotelInformationQuery,
  useUpdatePropertyMutation,
  useUpdateMetaDataMutation,
  useUpdateApiKeyMutation,
  useGetPropertyDetailsQuery,
  useCreateSimphonyWithComboMealsMutation,
  useSimphonyDiscountsLazyQuery,
  useSimphonyServiceChargesLazyQuery,
  useSimphonySignInQuery
} from "../../generated/graphql";

import swal from "sweetalert";

interface metaData {
  description: string;
  hotel_name: string;
  address: string;
  phone: string;
  email: string;
  feedback_desc: string;
  promotion_desc: string;
  aboutImg: string;
  directionImg: string;
  diningImg: string;
  serviceImg: string;
  roomsImg: string;
  directory_description: string;
  about: boolean;
  dining: boolean;
  directory: boolean;
  hotel_rooms: boolean;
  hotel_services: boolean;
  has_order_service_id: boolean;
  order_service_id: number;
}
interface propertyData {
  api_key: string;
  chat_url: string;
  domain: string;
  api_url: string;
  name: string;
  primary_color: string;
  primary_light: string;
  primary_text_color: string;
  secondary_text_color: string;
  img: string;
  currency: string;
  background_color: string;
  font: string;
  tile_color: string;
  by_dining: string;
  show_talk_to_us: boolean;
  show_main_category: boolean;
  has_simphony_pos: boolean;
  direct_simphony_pos_check: boolean;
  simphony_pos_url: string;
  pos_loc_ref: string;
  pos_rvc_ref: string;
  hide_all_menu: boolean;
  restaurant_email: any;
  country: string;
  palm_text: string;
  restaurant_phone: string;
  spa_id: string;
  button_bg: string;
  title_color: string;
  background_image: string
  transperancy: number
  logo_tile_bg: string
  logo_tile_transperancy: number
  star_rating:boolean
  sender_email:string
  send_to_simphony:boolean
  navbar_background_color: string
  navbar_active_background_color: string
  navbar_title_color: string
  navbar_active_title_color: string
  list_service: boolean
  feedback_text: string
  chat_text: string
}

const useHotelInformation = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();

  const [Loading, setLoading] = React.useState<boolean>(false);

  const [upsertProperty] = useUpdatePropertyMutation();

  const [upsertMetaData] = useUpdateMetaDataMutation();

  const [upsertApiKey] = useUpdateApiKeyMutation();

  const [getUploadSignedURL] = useGetUploadSignedUrlLazyQuery({
    fetchPolicy: "network-only",
  });

  //Get All Services
  const {
    data: hotelData,
    refetch: refetchData,
    loading: loadingHotel,
  } = useGetHotelInformationQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const {
    data: propertyDetails,
    refetch: refetchProperty,
    loading: loadingProperty,
  } = useGetPropertyDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const {
    data: signInToken,
    // refetch: refetchProperty,
    // loading: loadingProperty,
  } = useSimphonySignInQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const [isDisableProperty, setIsDisableProperty] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  // handle Image src

  const handlePropertyImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: any,
    field: string
  ) => {
    if (e.target.files != null) {
      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = property?.id;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });

        await setImage(field, uploadedImageURL);
        setIsDisableProperty(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  const handleImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: any,
    field: string
  ) => {
    if (e.target.files != null) {
      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = property?.id;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });

        await setImage(field, uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  //property data form register
  const {
    register: propertyRegister,
    handleSubmit: handleSubmitProperty,
    formState: { errors: propertyError, isDirty: isDirtyProperty },
    getValues: getPropertyValues,
    setValue: setPropertyValue,
    watch: propertyWatch,
    reset: propertyReset,
    control: propertyControl
  } = useForm<propertyData>({
    defaultValues: {
      api_key: "*********",
      name: propertyDetails?.getProperty?.name || "",
      domain: propertyDetails?.getProperty?.domain || "",
      api_url: propertyDetails?.getProperty?.hotlync_api_url || "",
      chat_url: propertyDetails?.getProperty?.chat_api_url || "",
      primary_color: propertyDetails?.getProperty?.primary_color || "",
      primary_light: propertyDetails?.getProperty?.primary_light || "",
      primary_text_color:
        propertyDetails?.getProperty?.primary_text_color || "",
      secondary_text_color:
        propertyDetails?.getProperty?.secondary_text_color || "",
      img: propertyDetails?.getProperty?.img || "",
      currency: propertyDetails?.getProperty?.currency || "",
      background_color: propertyDetails?.getProperty?.background_color || "",
      font: propertyDetails?.getProperty?.font || "",
      tile_color: propertyDetails?.getProperty?.tile_color || "",
      by_dining: propertyDetails?.getProperty?.by_dining || "",
      show_talk_to_us: propertyDetails?.getProperty?.show_talk_to_us || false,
      show_main_category:
        propertyDetails?.getProperty?.show_main_category || false,
      has_simphony_pos: propertyDetails?.getProperty?.has_simphony_pos || false,
      direct_simphony_pos_check:
        propertyDetails?.getProperty?.direct_simphony_pos_check || false,
      simphony_pos_url: propertyDetails?.getProperty?.simphony_pos_url || "",
      pos_loc_ref: propertyDetails?.getProperty?.pos_loc_ref || "",
      pos_rvc_ref: propertyDetails?.getProperty?.pos_rvc_ref || "",
      hide_all_menu: propertyDetails?.getProperty?.hide_all_menu || false,
      restaurant_email: propertyDetails?.getProperty?.restaurant_email || null,
      country: propertyDetails?.getProperty?.country || "",
      palm_text: propertyDetails?.getProperty?.palm_text || "",
      restaurant_phone: propertyDetails?.getProperty?.restaurant_phone || "",
      spa_id: propertyDetails?.getProperty?.spa_id || "",
      button_bg: propertyDetails?.getProperty?.button_bg || "",
      title_color: propertyDetails?.getProperty?.title_color || "",
      background_image: propertyDetails?.getProperty?.background_image || '',
      transperancy: propertyDetails?.getProperty?.transperancy || 1,
      logo_tile_bg: propertyDetails?.getProperty?.logo_tile_bg || '',
      logo_tile_transperancy: propertyDetails?.getProperty?.logo_tile_transperancy || 1,
      star_rating:propertyDetails?.getProperty?.star_rating || false,
      sender_email:propertyDetails?.getProperty?.sender_email || "",
      send_to_simphony:propertyDetails?.getProperty?.send_to_simphony || false,
      navbar_active_background_color: propertyDetails?.getProperty?.navbar_active_background_color || "",
      navbar_active_title_color: propertyDetails?.getProperty?.navbar_active_title_color || "",
      navbar_background_color: propertyDetails?.getProperty?.navbar_background_color || "",
      navbar_title_color: propertyDetails?.getProperty?.navbar_title_color || "",
      feedback_text: propertyDetails?.getProperty?.feedback_text || "",
      chat_text: propertyDetails?.getProperty?.chat_text || ""
    },
  });
  useEffect(() => {
    if (propertyDetails) {
      propertyReset({
        api_key: "*********",
        name: propertyDetails?.getProperty?.name || "",
        domain: propertyDetails?.getProperty?.domain || "",
        api_url: propertyDetails?.getProperty?.hotlync_api_url || "",
        chat_url: propertyDetails?.getProperty?.chat_api_url || "",
        primary_color: propertyDetails?.getProperty?.primary_color || "",
        primary_light: propertyDetails?.getProperty?.primary_light || "",
        primary_text_color:
          propertyDetails?.getProperty?.primary_text_color || "",
        secondary_text_color:
          propertyDetails?.getProperty?.secondary_text_color || "",
        img: propertyDetails?.getProperty?.img || "",
        currency: propertyDetails?.getProperty?.currency || "",
        background_color: propertyDetails?.getProperty?.background_color || "",
        font: propertyDetails?.getProperty?.font || "",
        tile_color: propertyDetails?.getProperty?.tile_color || "",
        by_dining: propertyDetails?.getProperty?.by_dining || "",
        show_talk_to_us: propertyDetails?.getProperty?.show_talk_to_us || false,
        show_main_category:
          propertyDetails?.getProperty?.show_main_category || false,
        has_simphony_pos:
          propertyDetails?.getProperty?.has_simphony_pos || false,
        direct_simphony_pos_check:
          propertyDetails?.getProperty?.direct_simphony_pos_check || false,
        simphony_pos_url: propertyDetails?.getProperty?.simphony_pos_url || "",
        pos_loc_ref: propertyDetails?.getProperty?.pos_loc_ref || "",
        pos_rvc_ref: propertyDetails?.getProperty?.pos_rvc_ref || "",
        hide_all_menu: propertyDetails?.getProperty?.hide_all_menu || false,
        restaurant_email:
          propertyDetails?.getProperty?.restaurant_email || null,
        country: propertyDetails?.getProperty?.country || "",
        palm_text: propertyDetails?.getProperty?.palm_text || "",
        restaurant_phone: propertyDetails?.getProperty?.restaurant_phone || "",
        spa_id: propertyDetails?.getProperty?.spa_id || "",
        button_bg: propertyDetails?.getProperty?.button_bg || "",
        title_color: propertyDetails?.getProperty?.title_color || "",
        background_image: propertyDetails?.getProperty?.background_image || "",
        transperancy: propertyDetails?.getProperty?.transperancy || 1,
        logo_tile_bg: propertyDetails?.getProperty?.logo_tile_bg || '',
        logo_tile_transperancy: propertyDetails?.getProperty?.logo_tile_transperancy || 1,
        star_rating:propertyDetails?.getProperty?.star_rating || false,
        sender_email:propertyDetails?.getProperty?.sender_email || "",
        send_to_simphony:propertyDetails?.getProperty?.send_to_simphony || false,
        navbar_active_background_color: propertyDetails?.getProperty?.navbar_active_background_color || "",
        navbar_active_title_color: propertyDetails?.getProperty?.navbar_active_title_color || "",
        navbar_background_color: propertyDetails?.getProperty?.navbar_background_color || "",
        navbar_title_color: propertyDetails?.getProperty?.navbar_title_color || "",
        feedback_text: propertyDetails?.getProperty?.feedback_text || "",
        chat_text: propertyDetails?.getProperty?.chat_text || ""
      });
    }
  }, [propertyDetails, propertyReset]);

  //Meta data Form Register
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue: setMetaValue,
    getValues: getMetaValue,
    watch: metaWatch,
    reset: metaReset,
    control,
  } = useForm<metaData>({
    defaultValues: {
      hotel_name: hotelData?.getMeta?.hotel_name || "",
      description: hotelData?.getMeta?.about?.desc || "",
      email: hotelData?.getMeta?.dir_con?.email || "",
      address: hotelData?.getMeta?.dir_con?.address || "",
      phone: hotelData?.getMeta?.dir_con?.phone || "",
      feedback_desc: hotelData?.getMeta?.feedback_desc || "",
      promotion_desc: hotelData?.getMeta?.promotion_desc || "",
      aboutImg: hotelData?.getMeta?.about?.img || "",
      directionImg: hotelData?.getMeta?.dir_con?.img || "",
      diningImg: hotelData?.getMeta?.dining_image || "",
      serviceImg: hotelData?.getMeta?.hotel_services_image || "",
      roomsImg: hotelData?.getMeta?.rooms_image || "",
      directory_description: hotelData?.getMeta?.directory_description || "",
      about: hotelData?.getMeta?.is_Activated?.about || false,
      dining: hotelData?.getMeta?.is_Activated?.dining || false,
      directory: hotelData?.getMeta?.is_Activated?.directory || false,
      hotel_rooms: hotelData?.getMeta?.is_Activated?.hotel_rooms || false,
      hotel_services: hotelData?.getMeta?.is_Activated?.hotel_services || false,
      has_order_service_id: hotelData?.getMeta?.has_order_service_id || false,
      order_service_id: hotelData?.getMeta?.order_service_id || 0,
    },
  });

  useEffect(() => {
    if (hotelData) {
      metaReset({
        hotel_name: hotelData?.getMeta?.hotel_name || "",
        description: hotelData?.getMeta?.about?.desc || "",
        email: hotelData?.getMeta?.dir_con?.email || "",
        address: hotelData?.getMeta?.dir_con?.address || "",
        phone: hotelData?.getMeta?.dir_con?.phone || "",
        feedback_desc: hotelData?.getMeta?.feedback_desc || "",
        promotion_desc: hotelData?.getMeta?.promotion_desc || "",
        aboutImg: hotelData?.getMeta?.about?.img || "",
        directionImg: hotelData?.getMeta?.dir_con?.img || "",
        diningImg: hotelData?.getMeta?.dining_image || "",
        serviceImg: hotelData?.getMeta?.hotel_services_image || "",
        roomsImg: hotelData?.getMeta?.rooms_image || "",
        directory_description: hotelData?.getMeta?.directory_description || "",
        about: hotelData?.getMeta?.is_Activated?.about || false,
        dining: hotelData?.getMeta?.is_Activated?.dining || false,
        directory: hotelData?.getMeta?.is_Activated?.directory || false,
        hotel_rooms: hotelData?.getMeta?.is_Activated?.hotel_rooms || false,
        hotel_services:
          hotelData?.getMeta?.is_Activated?.hotel_services || false,
        has_order_service_id: hotelData?.getMeta?.has_order_service_id || false,
        order_service_id: hotelData?.getMeta?.order_service_id || 0,
      });
    }
  }, [hotelData, metaReset]);

  //Upsert Property
  const onSubmitProperty = async (data: propertyData) => {
    if (data?.secondary_text_color === data?.tile_color) {
      swal({
        text: "Tile Color and Secondary Text Color are same, Kindly select contrasting color for better visibility",
        icon: "error",
      });
      return;
    }
    const email =
      typeof data?.restaurant_email === "string"
        ? data?.restaurant_email.split(",")
        : data?.restaurant_email;
    try {
      await upsertProperty({
        variables: {
          propertyInput: {
            id: property.id,
            name: data?.name,
            domain: data?.domain,
            chat_api_url: data.chat_url,
            hotlync_api_url: data?.api_url,
            primary_color: data?.primary_color,
            primary_light: data?.primary_light,
            primary_text_color: data?.primary_text_color,
            secondary_text_color: data?.secondary_text_color,
            img: data?.img,
            currency: data?.currency,
            background_color: data?.background_color,
            font: data?.font,
            tile_color: data?.tile_color,
            by_dining: data?.by_dining || "",
            show_talk_to_us: data?.show_talk_to_us || false,
            show_main_category: data?.show_main_category || false,
            has_simphony_pos: data?.has_simphony_pos || false,
            direct_simphony_pos_check: data?.direct_simphony_pos_check || false,
            simphony_pos_url: data?.simphony_pos_url || "",
            pos_loc_ref: data?.pos_loc_ref || "",
            pos_rvc_ref: data?.pos_rvc_ref || "",
            hide_all_menu: data?.hide_all_menu || false,
            restaurant_email: email || null,
            country: data?.country || "",
            palm_text: data?.palm_text || "",
            restaurant_phone: data?.restaurant_phone || "",
            spa_id: data?.spa_id || "",
            title_color: data?.title_color || "",
            button_bg: data?.button_bg || "",
            background_image: data?.background_image || "",
            transperancy: data?.transperancy,
            logo_tile_bg: data?.logo_tile_bg || "",
            logo_tile_transperancy: data?.logo_tile_transperancy,
            star_rating:data?.star_rating || false,
            sender_email:data?.sender_email || "",
            send_to_simphony:data?.send_to_simphony || false,
            navbar_background_color: data?.navbar_background_color || "",
            navbar_active_background_color: data?.navbar_active_background_color || "",
            navbar_title_color: data?.navbar_title_color || "",
            navbar_active_title_color: data?.navbar_active_title_color || "",
            feedback_text: data?.feedback_text || "",
            chat_text: data?.chat_text || ""

          },
        },
      });

      if (data.api_key !== "*********") {
        await upsertApiKey({
          variables: {
            apiKey: data?.api_key,
            propertyID: property.id,
          },
        });
        swal({
          text: "Property and API Key Updated",
          icon: "success",
        });
      } else {
        swal({
          text: "Property Updated",
          icon: "success",
        });
        refetchProperty();

      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  //Upsert Meta Data
  const onSubmitMetaData = async (metaData: metaData) => {
    try {
      await upsertMetaData({
        variables: {
          metaInput: {
            id: hotelData?.getMeta?.id,
            about: {
              desc: metaData?.description,
              img: metaData?.aboutImg,
            },
            dir_con: {
              img: metaData?.directionImg,
              phone: metaData?.phone,
              email: metaData?.email,
              address: metaData?.address,
            },
            dining_image: metaData?.diningImg,
            hotel_services_image: metaData?.serviceImg,
            rooms_image: metaData?.roomsImg,
            property_id: property.id,
            promotion_desc: metaData?.promotion_desc,
            hotel_name: metaData?.hotel_name,
            feedback_desc: metaData?.feedback_desc,
            directory_description: metaData?.directory_description,
            is_Activated: {
              dining: metaData?.dining,
              hotel_rooms: metaData?.hotel_rooms,
              hotel_services: metaData?.hotel_services,
              about: metaData?.about,
              directory: metaData?.directory,
            },
            has_order_service_id: metaData?.has_order_service_id,
            order_service_id: metaData?.order_service_id
          },
        },
      });

      swal({
        text: "Information Updated",
        icon: "success",
      });
      refetchData();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const [createPOSCheck] = useCreateSimphonyWithComboMealsMutation();
  const CreateCheckWithComboMeals = async () => {
    try {
      const res = await createPOSCheck({
        variables: {
          propertyID: property.id,
        },
      });
      if (res?.data?.createSimphonyWithComboMeals?.status !== 200) {
        swal({
          text:
            res?.data?.createSimphonyWithComboMeals?.message ||
            "Contact Support",
          icon: "error",
        });
      } else {
        swal({
          text: "Synced with POS",
          icon: "success",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  const [getDiscounts] = useSimphonyDiscountsLazyQuery();
  const GetDiscounts = async () => {
    try {
      const res = await getDiscounts({
        variables: {
          propertyID: property.id,
        },
      });
      if (res?.data?.simphonyDiscounts?.status !== 200) {
        swal({
          text: res?.data?.simphonyDiscounts?.message || "Contact Support",
          icon: "error",
        });
      } else {
        swal({
          text: JSON.stringify(res?.data?.simphonyDiscounts?.data),
          icon: "success",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  const [getServiceCharges] = useSimphonyServiceChargesLazyQuery();
  const GetServiceCharges = async () => {
    try {
      const res = await getServiceCharges({
        variables: {
          propertyID: property.id,
        },
      });
      if (res?.data?.simphonyServiceCharges?.status !== 200) {
        swal({
          text: res?.data?.simphonyServiceCharges?.message || "Contact Support",
          icon: "error",
        });
      } else {
        swal({
          text: JSON.stringify(res?.data?.simphonyServiceCharges?.data),
          icon: "success",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  function removeTypenameAndCopy(updatedData: any) {
    if (!updatedData || typeof updatedData !== "object") {
      return updatedData;
    }

    const result: any = Array.isArray(updatedData) ? [] : {};

    for (const key in updatedData) {
      if (key !== "__typename") {
        result[key] = removeTypenameAndCopy(updatedData[key]);
      }
    }

    return result;
  }

  const onSave = async (data: any) => {

    try {
      const updateLayout = {
        ...propertyDetails?.getProperty,
        layout: data,
      };
      const cleanedDetails = removeTypenameAndCopy(updateLayout);
      // setLoading(true);
      await upsertProperty({
        variables: {
          propertyInput: cleanedDetails,
        },
      });
  
      refetchProperty();
      // setLoading(false);
      swal({
        text: "Layout Updated",
        icon: "success",
      });
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onLayoutUpdate = async (status: boolean, cardName: string) => {
    setLoading(true);
    const updatedDetails = {
      ...propertyDetails?.getProperty, // Copy the existing Details object
      layout: propertyDetails?.getProperty?.layout?.map((layout) => {
        if (layout?.cardName === cardName) {
          return { ...layout, active: !status };
        }
        return layout;
      }),
    };

    let cleanedDetails = removeTypenameAndCopy(updatedDetails);

    await upsertProperty({
      variables: {
        propertyInput: cleanedDetails,
      },
    });

    refetchProperty();
    setLoading(false);
  };

  const onNameChange = async (dpName:string, cardName: string) => {

    try {
      setLoading(true);
      const updatedDetails = {
        ...propertyDetails?.getProperty, // Copy the existing Details object
        layout: propertyDetails?.getProperty?.layout?.map((layout) => {
          if (layout?.cardName === cardName) {
            return { ...layout, displayName:dpName };
          }
          return layout;
        }),
      };

      let cleanedDetails = removeTypenameAndCopy(updatedDetails);

      await upsertProperty({
        variables: {
          propertyInput: cleanedDetails,
        },
      });

      refetchProperty();
      setLoading(false);
      swal({
        text: "Text Updated",
        icon: "success",
      });
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
    
  };

  const onPalmWaveIconUpdate = async (status: boolean) => {
    setLoading(true);
    const updatePalmIcon = {
      ...propertyDetails?.getProperty,
      palm_icon: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updatePalmIcon);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };
  const onWhatsappIconUpdate = async (status: boolean) => {
    setLoading(true);
    const updateData = {
      ...propertyDetails?.getProperty,
      whatsapp_icon: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updateData);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };

  const onScheduleRequestUpdate = async (status: boolean) => {
    setLoading(true);
    const updateData = {
      ...propertyDetails?.getProperty,
      schedule_request: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updateData);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };

  const onShowListUpdate = async (status: boolean) => {
    setLoading(true);
    const updateData = {
      ...propertyDetails?.getProperty,
      list_service: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updateData);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };

  const onStartRatingUpdate = async (status: boolean) => {
    setLoading(true);
    const updateData = {
      ...propertyDetails?.getProperty,
      star_rating: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updateData);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };
  return {
    Details: propertyDetails?.getProperty,
    hotelDetails: hotelData?.getMeta,
    propertyRegister,
    handleSubmitProperty,
    propertyError,
    loading: loadingProperty || loadingHotel,
    isDirtyProperty,
    onSubmitProperty,
    register,
    handleSubmit,
    onSubmitMetaData,
    errors,
    handleImage,
    setPropertyValue,
    getPropertyValues,
    setMetaValue,
    isDirty,
    handlePropertyImage,
    metaWatch,
    propertyWatch,
    isDisable,
    isDisableProperty,
    getMetaValue,
    CreateCheckWithComboMeals,
    GetDiscounts,
    GetServiceCharges,
    control,
    refetchProperty,
    propertyControl,
    propertyReset,
    signInToken: signInToken?.simphonySignIn,
    Loading,
    onSave,
    onLayoutUpdate,
    onPalmWaveIconUpdate,
    onWhatsappIconUpdate,
    onStartRatingUpdate,
    onScheduleRequestUpdate,
    onShowListUpdate,
    onNameChange
  };
};

export default useHotelInformation;
