import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeightLight: 300, // Light
    fontSize: 12,
  },
  
});

export default theme;