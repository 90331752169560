import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { EggIcon } from "../../components/icons/EggIcon";
import { FishIcon } from "../../components/icons/FishIcon";
import { MilkIcon } from "../../components/icons/MilkIcon";
import { PeanutsIcon } from "../../components/icons/PeanutsIcon";
import { ShelfishIcon } from "../../components/icons/Shelfishicon";
import { SoybeansIcon } from "../../components/icons/SoybeansIcon";
import { TreenutsIcon } from "../../components/icons/TreenutsIcon";
import { WheatIcon } from "../../components/icons/WheatIcon";
import {
  Allergens,
  useAddAllergensMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";

interface prop {
  editData: Allergens | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
  isCustomized: boolean;
  setIsCustomized: (v: boolean) => void;
  allAllergens: Array<Allergens | null> | null | undefined;
}

const AllergenForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
  isCustomized,
  setIsCustomized,
}: // allAllergens,
prop) => {
  const [image, setImage] = React.useState<any>("");
  const [uploadIcon, setUploadIcon] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);

  React.useEffect(() => {
    if (!open) {
      setImage("");
      setUploadIcon("");
    }
  }, [open]);

  const icons = [
    {
      name: "FishIcon",
      icon: <FishIcon />,
    },
    {
      name: "MilkIcon",
      icon: <MilkIcon />,
    },
    {
      name: "TreenutsIcon",
      icon: <TreenutsIcon />,
    },
    {
      name: "WheatIcon",
      icon: <WheatIcon />,
    },
    {
      name: "ShelfishIcon",
      icon: <ShelfishIcon />,
    },
    {
      name: "PeanutsIcon",
      icon: <PeanutsIcon />,
    },
    {
      name: "SoybeansIcon",
      icon: <SoybeansIcon />,
    },
    {
      name: "EggIcon",
      icon: <EggIcon />,
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: AllergenReset,
  } = useForm<Allergens>({
    defaultValues: {
      name: editData?.name || "",
      icon: editData?.icon || "",
      uploaded_icon: editData?.uploaded_icon || "",
    },
  });

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    AllergenReset(
      editData || {
        name: "",
        icon: "",
        uploaded_icon: "",
      }
    );
  }, [editData, AllergenReset]);

  const [upsertAllergens] = useAddAllergensMutation();

  const onSubmit = async (data: Allergens) => {
    const uploaded_icon =
      data.icon !== "Customized"
        ? ""
        : image
        ? uploadIcon
        : data?.uploaded_icon;

    if (data.icon === "Customized" && !uploaded_icon) {
      swal({
        text: "Please Upload Icon",
        icon: "error",
      });
      return;
    }

    try {
      const res = await upsertAllergens({
        variables: {
          allergensInput: {
            id: editData?.id || "",
            name: data.name,
            icon: uploadIcon ? "" : data.icon,
            property_id: propertyId,
            uploaded_icon: uploaded_icon,
          },
        },
      });
      if (res.data?.upsertAllergens) {
        setIsCustomized(data.icon === "Customized");
        setOpen(false);
        setImage("");
        swal({
          text: "Allergen Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  const handleIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadIcon(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Allergen" : "Add New Allergen"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Allergen form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Allergen Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Icon
                </InputLabel>
                <Select
                  required={!isCustomized}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("icon", {
                    required: "icon  is required",
                  })}
                  label="Allergen Icon"
                  defaultValue={editData?.icon || ""}
                  onChange={(e) => {
                    const selectedIcon = e.target.value as string;
                    setIsCustomized(selectedIcon === "Customized");
                  }}
                >
                  {icons
                    // .filter((ic) => ic.name !== editData?.icon)
                    .map((ic, index) => (
                      <MenuItem key={index} value={ic.name}>
                        <ListItemIcon>{ic.icon}</ListItemIcon>
                        {ic.name}
                      </MenuItem>
                    ))}
                  <MenuItem value="Customized">Customized</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" justifyContent="center">
                <Typography color="text.secondary" gutterBottom align="center">
                  ------------OR------------
                </Typography>
              </Stack>
            </Grid>

            {isCustomized && (
              <Grid item md={12}>
                <Typography color="text.secondary" gutterBottom>
                  Upload Icon
                </Typography>
                {loadingImage ? (
                  <Box
                    sx={{
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "60px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  (editData?.uploaded_icon || image) && (
                    <Box>
                      <img
                        alt="Service"
                        width={"100px"}
                        src={image ? image : editData?.uploaded_icon}
                      />

                      {(uploadIcon || image) && (
                        <Box
                          component={IconButton}
                          onClick={() => setImage("")}
                        >
                          <Close />
                        </Box>
                      )}
                    </Box>
                  )
                )}
                <Button variant="outlined">
                  <label>
                    {editData ? "Update Icon" : "Add icon"}
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={handleIcon}
                    />
                  </label>
                </Button>
              </Grid>
            )}
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AllergenForm;
