import { Box, Tooltip } from "@mui/material";
import React from "react";
import useAllergen from "../../pages/Allregens/allergen-hooks";

export default function AllergenIcon({
  source,
  name,
}: {
  source: string;
  name: string;
}) {
  const { Allergens } = useAllergen();
  const foundAllergenIconName = Allergens?.find(
    (allergen: any) => allergen.uploaded_icon === source
  );
  return (
    <Tooltip title={foundAllergenIconName ? foundAllergenIconName?.name : name}>
      <Box
        component={"img"}
        src={source}
        style={{ width: "24px", height: "24px", borderRadius: "50%",marginRight:"6px",marginBottom:"5px" }}
      ></Box>
    </Tooltip>
  );
}
