import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import {
  Itinerary,
  Maybe,
  useAddItineraryMutation,
  useGetGuestListQuery,
  useGetItineraryTypesQuery,
} from "../../generated/graphql";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { TimeField } from "@mui/x-date-pickers/TimeField";

interface prop {
  editData: Itinerary | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetchActiveList: () => void;
  refetchCloseList: () => void;
  handleEditClose: () => void;
  open: boolean;
}

const ItineraryForm = ({
  editData,
  propertyId,
  setOpen,
  refetchActiveList,
  refetchCloseList,
  handleEditClose,
  open,
}: prop) => {
  const { data: guestLista, loading: loadingGuestList } = useGetGuestListQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: propertyId,
    },
  });
  const guestList = guestLista?.getGuestList || [];
  const { data: itineraryTypes } = useGetItineraryTypesQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: propertyId,
    },
  });

  const setDate = (time: Maybe<string> | undefined) => {
    if (typeof time === "string") {
      const date = new Date(
        `09/03/2023 ${time.split(":")[0]}:${time.split(":")[1]}:00 UTC`
      );
      return dayjs(date.toString());
    }
    return time;
  };
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: ItineraryReset,
    watch: ItineraryWatch,
    control,
  } = useForm<any>({
    defaultValues: {
      name: editData?.name || "",
      duration: editData?.duration || "",
      description: editData?.description || "",
      is_for_all: editData?.is_for_all || true,
      is_repeated: editData?.is_repeated || true,
      type: editData?.type || "",
      venue: editData?.venue || "",
      start_date: editData?.start_date || "",
      end_date: editData?.end_date || "",
      guest_list: editData?.guest_list || [],
    },
  });

  React.useEffect(() => {
    ItineraryReset(
      editData || {
        name: "",
        duration: "",
        description: "",
        type: "",
        venue: "",
      }
    );
  }, [editData, ItineraryReset, open]);

  const [upsertItinerary] = useAddItineraryMutation();

  console.log(editData);

  const onSubmit = async (data: Itinerary) => {
    try {
      const selectedGuestList: any = guestList
        .filter((guest: any) =>
          data?.guest_list?.includes(guest?.guest_id || "")
        )
        .map((guest) => ({
          guest_id: guest?.guest_id,
          room: guest?.room,
        }));

      const res = await upsertItinerary({
        variables: {
          itineraryInput: {
            id: editData?.id || "",
            name: data.name,
            duration: data.duration,
            property_id: propertyId,
            description: data?.description,
            is_for_all: data?.is_for_all,
            is_repeated: data?.is_repeated,
            type: data?.type,
            venue: data?.venue,
            time:
              typeof data.time === "string"
                ? editData?.time || ""
                : `${new Date(data.time || "").getUTCHours()}:${new Date(
                    data.time || ""
                  ).getUTCMinutes()}`,
            start_date: data.start_date,
            end_date: data.end_date,
            guest_list: selectedGuestList ? selectedGuestList : [],
          },
        },
      });
      if (res.data?.upsertItinerary) {
        setOpen(false);
        swal({
          text: "Itinerary Added Successfully",
          icon: "success",
        });
      }
      refetchActiveList();
      refetchCloseList();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={handleEditClose}>
        <DialogTitle
          color={"#fff"}
          bgcolor={(theme) => theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">
              {editData ? "Update Itinerary" : "Add New Itinerary"}
            </Typography>
            <IconButton color={"inherit"} onClick={handleEditClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={2}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Enter Itinerary Name"
                  {...register("name", {
                    maxLength: 60,
                    required: "Name is required!",
                  })}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Enter Itinerary desc"
                  {...register("description", {
                    required: "description is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={ItineraryWatch("is_for_all") || false}
                      {...register("is_for_all")}
                    />
                  }
                  label="Is For All"
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={ItineraryWatch("is_repeated") || false}
                      {...register("is_repeated")}
                    />
                  }
                  label="isRepeated"
                />
              </Grid>
              {!ItineraryWatch("is_for_all") && !loadingGuestList && (
                <Grid item md={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ backgroundColor: "white", padding: "0 5px" }}
                    >
                      Select Guests
                    </InputLabel>
                    <Controller
                      name="guest_list"
                      control={control}
                      defaultValue={
                        editData?.guest_list?.map((guest) => guest?.guest_id) ||
                        []
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Guests"
                          fullWidth
                          value={field.value || []}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          {guestList?.map((guest) => (
                            <MenuItem
                              key={guest?.guest_id || ""}
                              value={guest?.guest_id || ""}
                            >
                              {guest?.guest_name+" - "+guest?.room}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item md={12}>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="Select Start Date"
                      {...register("start_date", {
                        required: "Start date is required!",
                      })}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      label="Select End Date"
                      {...register("end_date", {
                        required: "End date is required!",
                      })}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <Controller
                      name="time"
                      render={({ field: { onChange, value } }) => (
                        <TimeField
                          label="Select Time"
                          value={setDate(value)}
                          onChange={(newValue) => onChange(newValue)}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Duration"
                      {...register("duration", {
                        // maxLength: 60,
                        required: "duration is required!",
                      })}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Itinerary Venue"
                  {...register("venue", {
                    // maxLength: 60,
                    required: "venue is required!",
                  })}
                />
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ backgroundColor: "white", padding: "0 5px" }}
                  >
                    Select Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("type", {
                      // required: "allergens is required",
                    })}
                    label="Type"
                    defaultValue={editData?.type}
                  >
                    {itineraryTypes?.getItineraryTypes?.map((types) => (
                      <MenuItem key={types?.id} value={types?.name || ""}>
                        {types?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isDirty}
                loading={false}
                sx={{
                  margin: "auto",
                  marginTop: "25px",
                }}
              >
                {editData ? "Update Data" : "Submit Data"}
              </LoadingButton>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ItineraryForm;
