import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Input,
  Stack,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { LexicalEditor } from "lexical";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import {$getRoot, $insertNodes} from 'lexical';
import {
  Room,
  useAddRoomMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
import NoteViewer from "../../components/Editor";
interface prop {
  editData: Room | null;
  propertyId: string;
  handleEditClose: () => void;
  setEditOpen: (v: boolean) => void;
  editOpen: boolean;
  refetch: () => void;
}

const RoomsForm = ({
  editData,
  propertyId,
  handleEditClose,
  setEditOpen,
  editOpen,
  refetch,
}: prop) => {
  const {
    register,
    handleSubmit: handleSubmitRoom,
    formState: { isDirty },
    setValue,
    reset: resetRooms,
  } = useForm<Room>({
    defaultValues: {
      desc: editData?.desc || "",
      room_type: editData?.room_type || "",
      img: editData?.img || "",
    },
  });
  React.useEffect(() => {
    resetRooms(editData||
      {
        desc: "",
        room_type: "",
        img: "",
      }
      );
  }, [editData,resetRooms]);

  const [isDisable, setIsDisable] = React.useState(true);
  const [roomImage, setRoomImage] = React.useState<any>("");
  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

    const onInitialEditorState = (
      editor: LexicalEditor
        )=>{
        let htmlString = editData?.desc || ""
        if (!htmlString.startsWith("<")) {
            htmlString = `<p>${htmlString}</p>`
        }
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlString, "text/html");
        
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        $insertNodes(nodes);
    };

    const onFormattingChange = 
        (editorState:string,editorInstance?: LexicalEditor) => {
          if (editorInstance && editData?.desc) {
            editorInstance.update(() => {
              const htmlString = $generateHtmlFromNodes(editorInstance, null);
              setValue('desc', htmlString)
            });
          }
      };

  const [upsertRoom] = useAddRoomMutation();
  // handle Image src
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setRoomImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };
  // Add Room Details
  const onSubmitRoomData = async (data: Room) => {
    try {
      const res = await upsertRoom({
        variables: {
          roomInput: {
            id: editData?.id || "",
            desc: data.desc,
            room_type: data.room_type,
            img: roomImage ? uploadImg : data.img,
            property_id: propertyId,
          },
        },
      });
      if (res.data?.upsertRoom?.id) {
        setEditOpen(false);
        setRoomImage("")
        swal({
          text: "Room Added Successfully",
          icon: "success",
        });
        refetch();
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return (
    <Dialog
      open={editOpen}
      onClose={handleEditClose}
    >
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Room" : "Add Room"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmitRoom(onSubmitRoomData)}>
          <Grid container spacing={2} mt={2}>
            {/* Room Type*/}
            <Grid item md={12}>
            <Typography color="text.primary" gutterBottom>
              Description
            </Typography>
              <NoteViewer 
                  onChange={
                    (editorState: string, editorInstance?: LexicalEditor) => {
                      onFormattingChange(editorState,editorInstance);
                    }}
                  initialEditorState={
                    (editor: LexicalEditor) => {
                      onInitialEditorState(editor);
                    }
                  }
                />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Room Type"
                {...register("room_type", {
                  maxLength: 30,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || roomImage) && (
                  <Box>
                    <img
                      alt="Room"
                      width={"250px"}
                      src={roomImage ? roomImage : editData?.img}
                    />

                    {roomImage && (
                      <Box
                        component={IconButton}
                        onClick={() => setRoomImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RoomsForm;
