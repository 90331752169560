import React from "react";
import AppRouter from "./pages/AppRouters";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./core/apolloClient";
import initAWSAmplify from "./core/awsAmplify";
import AuthContext from "./core/authContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material";
import theme from "./layout/theme";

initAWSAmplify();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContext>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </LocalizationProvider>
      </AuthContext>
    </ApolloProvider>
  );
}

export default App;
