import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { AlcoholIcon } from "../../components/icons/AlcoholIcon";
import { BreakfastIcon } from "../../components/icons/BreakfastIcon";
import { DessertIcon } from "../../components/icons/DessertIcon";
import { DrinksIcon } from "../../components/icons/DrinksIcon";
import { HotDrinksIcon } from "../../components/icons/HotDrinksIcon";
import { MainsIcon } from "../../components/icons/MainsIcon";
import { SaladIcon } from "../../components/icons/SaladIcon";
import { SoupIcon } from "../../components/icons/SoupIcon";
import { StarterIcon } from "../../components/icons/StarterIcon";
import {
  DineInCategories,
  useAddDineInCategoriesMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";

interface prop {
  editData: DineInCategories | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
  isCustomized: boolean;
  setIsCustomized: (v: boolean) => void;
}

const CategoryForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
  isCustomized,
  setIsCustomized,
}: prop) => {
  const [image, setImage] = React.useState<any>("");
  const [uploadIcon, setUploadIcon] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);
  const icons = [
    {
      name: "AlcoholIcon",
      icon: <AlcoholIcon />,
    },
    {
      name: "BreakfastIcon",
      icon: <BreakfastIcon />,
    },
    {
      name: "SaladIcon",
      icon: <SaladIcon />,
    },
    {
      name: "DessertIcon",
      icon: <DessertIcon />,
    },
    {
      name: "DrinksIcon",
      icon: <DrinksIcon />,
    },
    {
      name: "HotDrinksIcon",
      icon: <HotDrinksIcon />,
    },
    {
      name: "SoupIcon",
      icon: <SoupIcon />,
    },
    {
      name: "StarterIcon",
      icon: <StarterIcon />,
    },
    {
      name: "MainsIcon",
      icon: <MainsIcon />,
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: CategoryReset,
  } = useForm<DineInCategories>({
    defaultValues: {
      name: editData?.name || "",
      icon: editData?.icon || "",
      desc: editData?.desc || "",
      uploaded_icon: editData?.uploaded_icon || "",
    },
  });

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    CategoryReset(
      editData || {
        name: "",
        icon: "",
        desc: "",
        uploaded_icon: "",
      }
    );
  }, [editData, CategoryReset, open]);

  const [upsertCategories] = useAddDineInCategoriesMutation();

  const onSubmit = async (data: DineInCategories) => {
    const uploaded_icon =
      data.icon !== "Customized"
        ? ""
        : image
        ? uploadIcon
        : data?.uploaded_icon;
    try {
      const res = await upsertCategories({
        variables: {
          dineInCategoriesInput: {
            id: editData?.id || "",
            name: data.name,
            icon: data.icon || "",
            property_id: propertyId,
            desc: data?.desc,
            uploaded_icon: uploaded_icon,
          },
        },
      });
      if (res.data?.upsertDineInCategory) {
        setOpen(false);
        setImage("");
        swal({
          text: "Category Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadIcon(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Category" : "Add New Category"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Category form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Category Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Category desc"
                {...register("desc", {
                  // maxLength: 60,
                  required: "description is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Icon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("icon", {})}
                  label="Category Icon"
                  defaultValue={editData?.icon}
                  onChange={(e) => {
                    const selectedIcon = e.target.value as string;
                    setIsCustomized(selectedIcon === "Customized");
                  }}
                >
                  {icons.map((ic, index) => (
                    <MenuItem key={index} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.name}
                    </MenuItem>
                  ))}
                  <MenuItem value="Customized">Customized</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" justifyContent="center">
                <Typography color="text.secondary" gutterBottom align="center">
                  ------------OR------------
                </Typography>
              </Stack>
            </Grid>
            {isCustomized && (
              <Grid item md={12}>
                <Typography color="text.secondary" gutterBottom>
                  Upload Icon
                </Typography>
                {loadingImage ? (
                  <Box
                    sx={{
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "60px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  (editData?.uploaded_icon || image) && (
                    <Box>
                      <img
                        alt="Service"
                        width={"100px"}
                        src={image ? image : editData?.uploaded_icon}
                      />

                      {(uploadIcon || image) && (
                        <Box
                          component={IconButton}
                          onClick={() => setImage("")}
                        >
                          <Close />
                        </Box>
                      )}
                    </Box>
                  )
                )}
                <Button variant="outlined">
                  <label>
                    {editData ? "Update Icon" : "Add icon"}
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={handleIcon}
                    />
                  </label>
                </Button>
              </Grid>
            )}
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryForm;
